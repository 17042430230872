import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";
import ScrollAnimation from "@stromsky/react-animate-on-scroll";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Citigoogle() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Citi/Google/google.png"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Citi x Google" />

                     

                    Unfortunately, I cannot share the work here due to NDAs. I created a design concept of Google banking hosted by Citi and presented to the Google team. This lead to a partnership with Citi x Google Plex.  
                    Contact me if you want to see detail.
            


                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="2019" />
                       <Label label="Role" input="Designer" />
                       <Label label="For" input="Citi & Google" />

                    </div>
                  </div>

                  <hr></hr>

                 <div className="spacer" data-height="60"></div>
                <div className="row col section-wrapper">

                    <h2>Press Releases</h2>
                     
                    <div className="row blog-wrapper">
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                        >
                        <div className="blog-item rounded bg-white shadow-dark">
                            <div className="">

                            <Link to="https://www.coinspeaker.com/google-citi-bank-big-techs/">
                                <img src="/images/Portfolio/Citi/Google/article1.png" alt="Citi and Google" />
                            </Link>


                            </div>
                            <div className="details">
                            <h4 className="my-0 title">
                                <Link
                                to="https://www.coinspeaker.com/google-citi-bank-big-techs/"
                                >
                                Google Partners with Citi Bank Offering Checking Accounts 
                                </Link>
                            </h4>
                            <ul className="list-inline meta mb-0 mt-2" ></ul>
                            </div>
                        </div>
                    </ScrollAnimation>


                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                        >
                        <div className="blog-item rounded bg-white shadow-dark">
                            <div className="">

                            <Link to="https://www.citigroup.com/global/news/press-release/2020/citi-unveils-sneak-preview-of-the-citi-plex-account-by-google-pay">
                                <img src="/images/Portfolio/Citi/Google/article3.png" alt="Google Plex mockup" />
                            </Link>


                            </div>
                            <div className="details">
                            <h4 className="my-0 title">
                                <Link
                                to="https://www.citigroup.com/global/news/press-release/2020/citi-unveils-sneak-preview-of-the-citi-plex-account-by-google-pay"
                                >
                                Citi Unveils Sneak Preview of The Citi Plex Account by Google Pay
                                </Link>
                            </h4>
                            <ul className="list-inline meta mb-0 mt-2" ></ul>
                            </div>
                        </div>
                    </ScrollAnimation>

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/KEpz0Njp83E?si=qv1FCYfRiVte_n16" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
               
                </div>

                    
                </div>

                  </div>


          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Citigoogle;
