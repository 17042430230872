import React from "react";
import ScrollAnimation from "@stromsky/react-animate-on-scroll";

function Title({ title }) {
  return (
    <>
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <h1 className="section-title section-space">{title}</h1>
      </ScrollAnimation>
    </>
  );
}

export default Title;
