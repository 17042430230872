import React from "react";
import ScrollAnimation from "@stromsky/react-animate-on-scroll";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Post({ metaData }) {
  const { id, title, lock, company, image, link } = metaData;

  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="blog-item rounded bg-white shadow-dark">
        <div className="thumb">

          <Link to={`${link}`}>
            <img src={image} alt="image" />
          </Link>


        </div>
        <div className="details">
          <h4 className="my-0 title">
            <Link
              to={`${link}`}
            >
              {title}
            </Link>
          </h4>
          <ul className="list-inline meta mb-0 mt-2" >
            <li className="list-inline-item" >{company}</li>
           &nbsp;&nbsp;{lock}
          </ul>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Post;
