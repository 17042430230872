import React from "react";
import { ReactTyped } from "react-typed";
import { Link } from "react-scroll";

function Herosection(props) {


  return (
    <section
      id="home"
      className={
        props.light
          ? "home d-flex align-items-center light"
          : "home d-flex align-items-center"
      }
    >
      <div className="container">
        <div className="intro">
          <img src="images/profilepic.svg" alt="Kristine" className="mb-4" />

          <h1 className="mb-2 mt-0">Kristine Nguyen</h1>
          <p>
            I'm {" "}
            <ReactTyped
              strings={[
                " ",
                "a product designer who codes",
                "bridging design and code together",
                "currently working at Apple via AMP",
                "a boba lover"

              ]}
              typeSpeed={80}
              backSpeed={40}
              attr="value"
              loop
            >
              <label value></label>
            </ReactTyped>
          </p>
         

        

          <div className="mt-5">
            <a
              className="btn btn-default"
              href="https://www.linkedin.com/in/knguyen9095/"
              spy={true}
              smooth={true}
              duration={500}
            >
              LinkedIn
            </a>
          </div>

          
         

        </div>

        <div className="scroll-down">
          <Link
            to="section-about"
            spy={true}
            smooth={true}
            duration={500}
            className="mouse-wrapper"
          >
            <span>Scroll Down</span>
            <span className="mouse">
              <span className="wheel"></span>
            </span>
          </Link>
        </div>

      </div>
    </section>
  );
}

export default Herosection;
