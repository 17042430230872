import React from "react";
import Post from "../elements/Post";
import Pagetitle from "../elements/Pagetitle";

const allWork = [
  {
    id: 1,
    title: "ROSKO Redesign",
    image: "images/Portfolio/ROSKO/thumbnail.png",
    link: "/rosko",
    lock: "",
    date: "09 February, 2020",
    company: "Savage Social Technology",
    category: "Reviews",
  },

  {
    id: 3,
    title: "Compliment Booster of the Day",
    image: "images/Portfolio/Alexa/complimentbooster.png",
    link: "/alexacompliment",
    lock: "",
    date: "06 February, 2020",
    company: "Personal Project",
    category: "Business",
  },
    {
    id: 4,
    title: "Alexa: Gift Genie",
    image: "images/Portfolio/Alexa/giftgenie.png",
    link: "/alexagift",
    date: "06 February, 2020",
    company: "Personal Project",
    category: "Business",
  },

  {
    id: 5,
    title: "Don't Starve CrockPot Helper",
    image: "images/Portfolio/Alexa/dontstarve.png",
    link: "/alexadontstarve",
    date: "06 February, 2020",
    company: "Personal Project",
    category: "Business",
  },

  {
    id: 6,
    title: "UberEats: Add One Feature",
    image: "images/Portfolio/UberEats/thumbnail.png",
    link: "/ubereats",
    date: "06 February, 2020",
    company: "Design Exercise Challenge",
    category: "Business",
  },

    {
    id: 7,
    title: "Rootify",
    image: "images/Portfolio/Rootify/thumbnail.webp",
  link: "/rootify",
    date: "06 February, 2020",
    company: "Class Project",
    category: "Business",
  },


 {
    id: 8,
    title: "Open a High Yield Savings Account",
    image: "images/Portfolio/Citi/OpenAccount/HYSA.svg",
    link: "/hysa",
    date: "06 February, 2020",
    lock: "",
    company: "Citi",
    category: "Business",
  },

   {
    id: 9,
    title: "Make a Wire Transfer",
    image: "images/Portfolio/Citi/MakeaWireTransfer/thumbnail.png",
    link: "/wiretransfer",
    lock: "",
    date: "06 February, 2020",
    company: "Citi",
    category: "Business",
  },

    {
    id: 10,
    title: "Add a Payee",
    image: "images/Portfolio/Citi/AddNewPayee/thumbnail.svg",
    link: "/addpayee",
    date: "06 February, 2020",
    lock: "",
    company: "Citi",
    category: "Business",
  },


  {
    id: 11,
    title: "Citi X Google",
    image: "images/Portfolio/Citi/Google/google.png",
    link: "/citigoogle",
    lock: "",
    date: "2019",
    company: "Citi for Google",
    category: "Business",
  },

  {
    id: 12,
    title: "Investment Banking Internal Tools",
    image: "images/Portfolio/GoldmanSachs/DM/dmcover.png",
    link: "/goldmansachs",
    lock: "",
    date: "2021-2022",
    company: "Goldman Sachs",
    category: "Business",
  },
  {
    id: 12,
    title: "Jobseeker Curated Board Campaign",
    image: "images/Portfolio/Indeed/thumbnail/IndeedJSXBoard.svg",
    link: "/IndeedJSXboard",
    lock: "",
    date: "2022-2024",
    company: "Indeed",
    category: "Business",
  },
  {
    id: 12,
    title: "🚧 Coming soon 🚧",
    image: "images/Portfolio/Indeed/Indeed.svg",
    link: "/",
    lock: "",
    date: "2022-2024",
    company: "Indeed",
    category: "Business",
  },

];

function Portfolio() {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="Work" />
        <div className="row blog-wrapper">
          {allWork.slice(0).reverse().map((metaItem, id) => (
            <div className="col-md-6" key={id}>
              <Post metaData={metaItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>

        </div>
      </div>
    </section>
  );
}

export default Portfolio;
