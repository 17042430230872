import React from "react";

function Label({ label,input }) {
  return (
    <>
     <div className="label-wrapper">
        <div className="label">{label}</div>
        <div className="input">{input}</div>
      </div>
    </>
  );
}

export default Label;
