import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Ubereats() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/UberEats/thumbnail.png"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="UberEats: Add One Feature" />

                       Challenge: Take an existing app of your choice and add a feature of your choice to it. What are the benefits of the feature enhancement? 3 days project

                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="2017 - 3 Days" />
                       <Label label="Role" input="UX/UI Designer" />
                       <Label label="For" input="Design Exercise" />

                    </div>
                  </div>
                  
                   <hr></hr>

                <div className="spacer" data-height="60"></div>
                <div className="row col section-wrapper">
                  
                      <h2>The Challenge</h2>
                      Take an existing app of your choice and add a feature of your choice to it. What are the benefits of the feature enhancement? This is a 3 days project. So I decided to work on UberEats app.

                       <div className="spacer" data-height="96"></div>

                      <h2>The Approach</h2>
                        I was a little stumped because I remember having many features ideas for a variety of apps. Since the apps have been recently updated, the documentation implemented the features that I have thought of in the last year. But then, I knew there is one particular app that I had a problem with and hopefully other people have a problem with it too. 
                      
                      <div className="spacer" data-height="96"></div> 
                       <h2>The Problem</h2>
                          The problem that people may have encountered if the drivers are unfamiliar with the neighborhood, especially if the driver’s map of choice leading them to the wrong direction/house. In my case, The UberEats driver always ends up at the back of my house, and I have to call them to give them proper direction. I admit that my neighborhood is very confusing to navigate, and the maps aren’t doing a very good job either.
                  

                </div>
              
                 





                


 <div className="spacer" data-height="96"></div>
                  <div className="row col section-wrapper blogheader">
                        <h1>The Process</h1>
                        
                  </div>

  <div className="spacer" data-height="96"></div>


                    <div className="row section-wrapper">
                     <h3>User Research</h3>

                    <div class="section-space"></div>
                    I conducted a quick survey via Google Forms. I sent out these surveys for participates who used UberEats. I found the participants through Tumblr, where I managed a blog with 26,000 followers all over the world.
                </div>
                    <img className="section-image-pad" src="/images/Portfolio/UberEats/research.webp" />
                  

                    <div class="section-space"></div>

                        <div className="row">
                          <p></p>
                              <div className="col-sm question"><h4>Questions</h4> 
                              <ul className="question">
                                <li>Did your UberEats driver have gotten lost or have a hard time finding your location?</li>
                                <li>If so, explain or give a story how did it happen? </li>
                                <li>How often does that happen?</li>
                              </ul>
                              </div>

                              <div className="col-sm"><h4>Result</h4> 
                                  31 Responses (9 out of 31) 29% of the participant has experienced the problem
                              </div>

                              <div className="col-sm"><h4>So what?</h4>
                                Well ideally for Uber, that’s 29% of lost customers! If the customer continually has the same problem with every order, then they will likely stop using Uber Eats in general. I almost did. Let’s hopefully Uber try to reduce it to 5%!
                              </div>
                          </div>


<div className="spacer" data-height="96"></div>
                  <div className="row">

                            <p></p>
                        
                            <center><blockquote>"Gps keeps taking drivers to the street behind my house"</blockquote></center> 
                            <center><blockquote> "The driver just got confused, my house number isn’t super easy to find so I totally understand"</blockquote></center>
                            <center><blockquote> "Once the driver was an entire street over and I had to wait outside until the driver found the street I was on. A second time the driver had parked halfway down the block and I had to walk to his car (in the dark, around midnight) to retrieve my meal)."</blockquote></center>
                            

                   </div>


                  <div className="spacer" data-height="96"></div>

                        <h2>Common Findings</h2>
                   
                    <div className="row spacer">

                            <p></p>
                            <div className="col"> <center><h4> 1. GPS redirects to the wrong address</h4></center> </div>
                            <div className="col"> <center><h4> 2. The driver have trouble finding the house</h4></center> </div>

                   </div>

                  <div className="spacer" data-height="96"></div>
                    
                      <h2>The Solution</h2>
                   
                     After conducting research and finding the common patterns, this is my solution. My solution enables the user to draw lines on the map where the driver should be going. This is part of the notes attached and will probably be in a specific range. This feature will be presented at the end of checkout.
                    Why? I did extensive research on the uber driver side, and I realized the driver has the ability to use their favorite 3rd party map app (Apple, Google, etc.). My first initial idea is to use the drawn shortcut direction and tell the map to alter the direction. However, I find it it’s a difficult solution since we are using 3rd party app, not uber. I mistakenly assumed that uber use their own map to navigate the driver. So I alter the solution is to add the drawn route onto the uber map on the main menu. In addition, the ubereats customer can add the direction where to go.

                   
                    
                
                     
                  <div className="spacer" data-height="96"></div>
                 <img className="section-image-pad" src="/images/Portfolio/UberEats/sketch1.jpeg" />
                 Above: The sketch is through the user side of the app. I follow the Uber design pattern and features that were already implemented.
                    
                  <div className="spacer" data-height="96"></div>
                  
                   <div className="row">
                      <div className="col-sm"><img src="/images/Portfolio/UberEats/sketch2.jpeg" /></div>
                      <div className="col-sm">This sketch is the driver view of the app. At first, I had no idea what the driver side of the app would look like. So I have to look all over the internet to find screenshots and youtube videos how the process work. So I follow that format. I noticed, that the driver can use map of their choice to navigate through. I initially wanted to change the direction on the direction of the map they use, but since they are allowed to use 3rd party program, I have to figure out a different method to show the mark location. So, I made an option to have an image marked on the Uber’s map. So the driver can refer back when they are near the location and read the map.</div>
                   </div>

                


              
      
              <div className="spacer" data-height="96"></div>
              <img className="section-image-pad" src="/images/Portfolio/UberEats/hifi1.jpeg" />
              <img className="section-image-pad" src="/images/Portfolio/UberEats/hifi2.jpeg" />
              <img className="section-image-pad" src="/images/Portfolio/UberEats/hifi3.jpeg" />
         


              <div className="spacer" data-height="96"></div>

                  
                    <h2>Conclusion – After Thoughts</h2>
                
                 It was an interesting design challenge that I really enjoy. Perhaps I can look into this exercise more in-depth.

                  <div className="spacer" data-height="60"></div>
                
                    <h2>What would I have done differently?</h2>
                
             
                  During the survey interview process, I should have asked how they feel about the situation, how they solve the problem, and what feature they would like to add to alleviate their problem.
I should have survey Uber Driver with the same set of questions too.
If I had more time, I would have done some user testing and get some feedback, but this is a fast deadline. So I’m treating this as if this is a next sprint meeting.
<div className="spacer" data-height="96"></div>


          </div>
        </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Ubereats;
