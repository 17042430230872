import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Alexadontstarve() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Alexa/dontstarve.png"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Alexa Don't Starve CrockPot Helper" />

                    Having trouble creating a crock pot recipe on Don't Starve? Don't fret! Don't Starve Crock Pot Recipe help you list all the recipe from dragon pie to monster tartare. Just say ask crockpot item and the helper will tell you the recipe. Complete with all recipes from the game

                  <div className="mt-4">
                            <a 
                              className="btn btn-default"
                              href="https://www.amazon.com/Kristine-Nguyen-Starve-CrockPot-Helper/dp/B01MTNT2RN/ref=sr_1_7?dchild=1&keywords=Kristine+Nguyen&qid=1618240239&sr=8-7"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              Download Here
                             </a> </div>


                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="November 2016" />
                       <Label label="Role" input="Developer" />
                       <Label label="For" input="Personal Project" />

                    </div>
                  </div>
                  </div>


          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Alexadontstarve;
