import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Herosection from "../components/layouts/Herosection";
import Portfolio from "../components/layouts/Portfolio";


function Lock() {

  return (
    <>
  <Headerpage />

      <section id="home" className="home d-flex align-items-center" >
      <div className="container">
        <div className="intro ">
        <h1>&#128274;</h1>
        <div className="opacityset"><h1>Uh Oh. This project isn't available to view for the public</h1></div>

        <div className="mt-4">
            <a 
              className="btn btn-default"
              href="mailto:knguyen9095@gmail.com"
              spy={true}
              smooth={true}
              duration={500}
            >
              Contact Me
            </a>
            </div>
        </div>
        </div>
   </section>
         
   <Footer />
    </>
  );
}

export default Lock;
