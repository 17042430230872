import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Rosko from "./pages/Rosko";
import Ubereats from "./pages/Ubereats";
import ClassOpen from "./pages/ClassOpen";
import Rootify from "./pages/Rootify";
import Lock from "./pages/Lock";
import Alexacompliment from "./pages/Alexacompliment";
import Alexagift from "./pages/Alexagift";
import Alexadontstarve from "./pages/Alexadontstarve";
import Hysa from "./pages/HYSA";
import Wiretransfer from "./pages/Wiretransfer";
import AddPayee from "./pages/AddPayee";
import Goldmansachs from "./pages/Goldmansachs";
import Password from "./pages/Password";
import ScrollToTop from "./components/elements/ScrollToTop";
import "./App.scss";
import Citigoogle from "./pages/Citigoogle";
import IndeedJSXboard from "./pages/IndeedJSXboard";

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>


        <Route path="/" element={<Home/>}/>
        <Route path="/rosko" element={<Rosko/>}/>
        <Route path="/ubereats" element={<Ubereats/>}/>
        <Route path="/classopen" element={<ClassOpen/>}/>
        <Route path="/rootify" element={<Rootify/>}/>
        <Route path="/lock" element={<Lock/>}/>
        <Route path="/alexacompliment" element={<Alexacompliment/>}/>
        <Route path="/alexagift" element={<Alexagift/>}/>
        <Route path="/alexadontstarve" element={<Alexadontstarve/>}/>
        <Route path="/hysa" element={<Hysa/>}/>
        <Route path="/wiretransfer" element={<Wiretransfer/>}/>
        <Route path="/addpayee" element={<AddPayee/>}/>
        <Route path="/password" element={<Password/>}/>
        <Route path="/goldmansachs" element={<Goldmansachs/>}/>
        <Route path="/citigoogle" element={<Citigoogle/>}/>
        <Route path="/indeedjsxboard" element={<IndeedJSXboard/>}/>
      </Routes>
      </BrowserRouter>
  );
}

export default App;
