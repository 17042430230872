import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";
import ScrollAnimation from "@stromsky/react-animate-on-scroll";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function IndeedJSXBoard() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Indeed/thumbnail/IndeedJSXBoard.svg"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Jobseeker Curated Job Board Campaign " />

                     
                      A marketing email campaign allows jobseeker to have a curated job board on specific job titles and partnerships. 
                      <p></p>
                      <p> <strong>Result: </strong>Launched in September 2023 and leveraged the template to expand to other parts of the industry for jobseekers to find a job. Over ~1.8 million unique visitors have interacted since September 2023 - May 2024</p>
                      <div className="mt-4">
                            <a 
                              className="btn btn-default"
                              href="https://www.indeed.com/job-search-services/retailjobs"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              View it live!
                             </a> </div>
                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="1 week" />
                       <Label label="Role" input="Designer" />
                       <Label label="For" input="Indeed" />

                    </div>
                  </div>

                  <hr></hr>

                 <div className="spacer" data-height="60"></div>
                <div className="row col section-wrapper">

                    <h2>My Role</h2>
                     
                    <div className="row blog-wrapper">
                    My role as a designer is to create a template for a curated job seeker curated job board for the Jobseeker Marketing team. 
                    With little information on hand, I spearheaded the direction the template is differeiate from Indeed's job board, but still maintaining the similar user experience. 
                    The curated job board campaign has been customized over 500 job titles supported. 

                    <img className="section-image-pad" src="/images/Portfolio/Indeed/jsxboard/designcomponent.svg" />

                </div>

          
                                    
                    <center><a
                      className="btn btn-default"
                      href="https://www.indeed.com/job-search-services/retailjobs"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      View it live!
                    </a> </center> </div>
                    
                ]

                  </div>


          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default IndeedJSXBoard;
