import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Hysa() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Citi/OpenAccount/HYSA.svg"/>

                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Open a High Yield Savings Account" />

                      With the goal of increasing deposits, the project family was kicked off in November 26 to deliver to market the capabilities to grow the volume of multiple relationship clients in the US by targeting our existing card base. Capabilities will be delivered incrementally starting with the MVP launch in Jan 2019. The MVP release shall allow eligible cards customers to open and fund high yield savings account by delivering a streamlined that includes a short form and simplified funding experience by leveraging the bank accounts associated with the customers card account. The MVP capabilities shall be made available on both desktop and iOS app.
                      <p></p>
                       The majority of our Citi customers have Credit Card relations only. Our goal is to convert our Citi-only Credit Card customers into multi-relationship customers by promoting customers to open a checking and savings account. We want to make funding an account easier and the fastest way possible.
                        <div class="section-space"></div>
                      <strong>Results: </strong>Launched on January 2019. Within 2 months since launch, we exceed our deposit goal by 200% by accumulating over 2 billion dollars of deposit.
                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="Nov 2018 - Dec 2018" />
                       <Label label="Role" input="UX Designer" />
                       <Label label="For" input="Citi / Aquire & Growth Team" />
                       <Label label="Team" input="4 Designers, 3 Project Managers, 2 Engineering Team" />

                    </div>
                  </div>

                   <hr></hr>

                 <div className="spacer" data-height="60"></div>
                <div className="row col section-wrapper">

                    <h2>The Problem</h2>
                     Citi Credit Card Customers don’t really have the incentive to bank with us. Customers are unaware other Citi products provide. From the data perspective, we've found that there's a stagnant of high drop off rates during the application process.

                </div>

                  <div class="section-space"></div>
                <div className="spacer" data-height="60"></div>
                <h4>How can we convert our Citi Credit Card-only customers into multi-relationship customers by promoting a high yield savings account offer? </h4>


                 <div className="spacer" data-height="96"></div>

                        <h2>The Approach</h2>
                        We need to first understand the user journey of applying an high yeild savings account. What are the pain points? What's the main reason that can cause a potential drop off rate?
                        Our team did not have much insight into the experience of opening a Savings account with Citi. We partner with our project manager to receive some insight.
                        We did an internal workshop with all the team members, and highlight the user journey

                         <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/journeymap.png" />

                          <h2>The Goals</h2>

                          <p></p>

                           <div className="question">
                           <ol class="number">
                              <li> Reduce the lengthy process of opening an account to something more digestible to read and fill out.</li>
                              <li>Optimized the terms and conditions experience. </li>
                              <li>Abolished receiving the ATM PIN Code via postal mail.</li>
                              <li>Allow customers to fund their account instantly or later via digital method.</li>
                            </ol>
                            </div>

                           <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/wireframe.png" />


                            <h2>Discoveries & Usability Testing</h2>
                          After the first round of wireframes design, I began critiquing and identifying  the potential problems that may cause in the user experience and run through usability testing to validate.
                          Our product managers were avid of having fewer clicks for the customers, but it’s not ideal for the customers since there’s information overload. We need to break it up into steps.
                            <p></p>
                          <p> Second, the first iteration of using a big banner as progress bar had a high negitive reaction.They didn't understand why the big number was there, and they overlook the small "STEP 1 OF 5".
                          We did two iternation of usability testing and more internal design review with the team: PMs, Legal, and Copywriter.
                          <p>There are more to this usability testing and discovery, please <a className="main" href="mailto:knguyen9095@gmail.com">contact me</a> to learn more  </p>
                          </p>

                          <p></p>

                          <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/hysausability.png" />

              <h2>The Outcome</h2>
              We keep in mind with our users the fastest way possible to open an account.
              <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/hysa1.png" />
              <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/hysa2.png" />
              <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/hysa3.png" />
              <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/hysa4.png" />
              <img className="section-image-pad" src="/images/Portfolio/Citi/OpenAccount/hysa5.png" />



              <div className="mt-4">

                
                            <center><a
                              className="btn btn-default"
                              href="https://www.figma.com/proto/a7z2aFdeEJ2PiSgw0NBceC/MBOL_iOS_HYSA_HK_HMR1_?page-id=1%3A6650&node-id=1%3A6666&viewport=478%2C354%2C0.42&scaling=scale-down&starting-point-node-id=1%3A6666"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              View Prototype
                             </a> </center> </div>

              <h2>Results</h2>
             Citi’s effort to broaden and deepen relationships with Citi clients outside of the bank’s core markets in the US has already shown great momentum - of nearly <strong>$5 billion</strong> in digital deposits so far in the 2019, two thirds came from customers outside the bank’s existing branch footprint.
             </div>






          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>

          <Footer />
    </>
  );
}

export default Hysa;
