import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Goldmansachs() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/GoldmanSachs/DM/dmcover.png"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Goldman Sachs" />

                       Unfortunately, due to privacy reasons, I can’t show the bulk of my work that I did at Goldman Sachs. So for now, you’ll just have to trust I did work while I was there.  I was a product designer on the Investment Banking Division team, leading design on multiple internal products, primarily focused on improving investment banker's workflow for initaiting IPO. My strong skill is primarily strive fixing the mobile experience first. Here are some brief notes about the work I did during this time.

                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="July 2021 - October 2022" />
                       <Label label="Role" input="Product Designer" />
                       <Label label="For" input="Goldman Sachs" />

                    </div>
                  </div>
                  
                   <hr></hr>

              
                <div className="row col section-wrapper">
            
                      <img className="section-image-pad" src="/images/Portfolio/GoldmanSachs/DM/dm.png" />
                      <h2>Deal Management</h2>
                      The process of executing IPOs lacked transparency across all involved teams. Manually tracking the status of each deal was challenging and burdensome, especially when communicating with multiple teams and managing multiple deals simultaneously. I designed to improve their process autonomously and provide visibility among the team.
                      <ul>
                        <li>Designed a brand new internal application from research towards design handoff in the span of 6 months. </li>
                        <li>Conducted a high level user journey map of all the teams that are involved during the lifecycle of a deal to IPO.</li>
                        <li>Planned the roadmap with the PM and engineers into phases.</li>
                        <li>Contribute to the design system and storybook with the engineers to use the component across internal Goldman Sachs application.</li>
                        </ul>

                      <div className="spacer" data-height="96"></div>
                      <img className="section-image-pad" src="/images/Portfolio/GoldmanSachs/salesforce.png" />
                      <h2>Salesforce</h2>
                      Designed an autonomous experience to provide relevant opportunites to build a stronger client's relationship on the go for senior bankers through mobile first experience and desktop.
                      <ul>
                        <li>Solved a complex design solution work around with sensitive private data stored onto Salesforce.</li>
                        <li>Built a customized email template through code.</li>
                        <li>Advocating and designing custom components based on our use case, improving the Salesforce design system, and contributing to the internal design system. </li>
                        <li>Heavily involved assisting with the engineers on the front end experience and brainstormed to create global components. </li>
                     </ul>

                      <div className="spacer" data-height="96"></div> 
                      <img className="section-image-pad" src="/images/Portfolio/GoldmanSachs/bankerbio.png" />
                       <h2>PowerPoint Banker Bio Plugin</h2>
                       Junior bankers spend many hours on building a deck for senior bankers. Going through multiple past decks about the senior banker and finding a suitable company’s logo takes majority of their time. 
                       <ul>
                        <li>Reduced slide deck creation time for junior bankers by approximately 60% by providing a plugin that creates a template with prefilled data. </li>
                        <li>Designed a solution for the plugin to be fully customizable to prevent any restriction. </li>
                      
                     </ul>

                </div>
              
                 





  <div className="spacer" data-height="96"></div>




          </div>
        </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Goldmansachs;
