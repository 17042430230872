import React from "react";
import ReactDOM from 'react-dom';
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";
import Herosection from "../components/layouts/Herosection";
import Portfolio from "../components/layouts/Portfolio";


function AddPayee() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Citi/AddNewPayee/thumbnail.svg"/>

                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Add a Payee" />


                      <p></p>

                      Add a Payee is a step where you preregister your contact's banker information before you can do any money transfer. Once you're set up, you can send the money anytime.
                      <p></p><a className="main" href="mailto:knguyen9095@gmail.com">Contact me</a> for more indepth design process.

                       <p></p><strong>Launched in November 2019</strong>
                        <div class="section-space"></div>

                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="2018-2019" />
                       <Label label="Role" input="UX Designer" />
                       <Label label="For" input="Citi / Pay Domain" />
                       <Label label="Team" input="2 Designers, 2 Project Managers, 1 Engineering Team" />

                    </div>
                  </div>

                   <hr></hr>

                 <div className="spacer" data-height="60"></div>
                <div className="row col section-wrapper">

                    <h2>The Background</h2>
                    Implementing new payment rails into the mobile experience. What are payment rails? A payment rail is a platform that allows all digital money transfers to be made between payers and payees, regardless of country, currency, digital payment method, or whether the payer or payee is a business or consumer. Each payment rail differs in how it carries out this process based on the payment type, speed, technology, or geographical location. Example of payment rails: Wire Transfers, Zelle, Instant Payment, Automated Clearing House (ACH), and many more.

                    <img className="section-image-pad" src="/images/Portfolio/Citi/AddNewPayee/background.svg" style={{ width: 400, height: 400 }}/>

                    <p></p>
                    <h4>The original experience on the mobile was limiting.</h4>
                    When I started this project, other forms of payment rails didn't exist on the mobile app. Customers could only send to other people who banked with Citi. This caused frustration when we redirect customers to go on desktop in order to send a wire transfers to other banks.

                    <img className="section-image-pad" src="/images/Portfolio/Citi/AddNewPayee/ogaddapayee.svg"/>


                </div>

                <h2>The Goals</h2>

                <p></p>

                 <div className="question">
                 <ol class="number">
                    <li> How can we guide and educate customers to new payment rails?</li>
                    <li> How can we unify all the payee's bank information into one contact?</li>
                    <li> ​​How can we scale that experience as we add more rails in the future? </li>
                  </ol>
                  </div>

                  <div class="section-space"></div>
                <div className="spacer" data-height="60"></div>



                        <h2>The Research</h2>

                        <h4> What's the current knowledge about payment rails and the current experience of sending money to someone?  </h4>
                        SOURCE: McKinsey survey, July 2018, N = 480
                        <div className="spacer" data-height="60"></div>

                        <img className="section-image-pad" src="/images/Portfolio/Citi/AddNewPayee/survey.svg"/>


                        <div className="spacer" data-height="60"></div>
                        <h2> Competitor Anlaysis </h2>
                            To understand adding a payee in different contexts, we analyzed the interaction model of other platforms like Wells Fargo, Chase, and Xoom (Paypal).
                           <img className="section-image-pad" src="/images/Portfolio/Citi/AddNewPayee/banks.svg" />


                            <h2>Exploring Design Treatments</h2>
                            The first iterations of the wireframes used the original Citi design system created by an agency. While doing the usability phasing, we learned that there was a change of direction to break away from the design system into a brand new concept.
                             <p></p>
                            The second design explored and showcase new UI treatement of bold prominent fonts.

                          <img className="section-image-pad" src="/images/Portfolio/Citi/AddNewPayee/draft1.png" />

                           <img className="section-image-pad" src="/images/Portfolio/Citi/AddNewPayee/explore.svg" />


              <h2>The Outcome</h2>
               We continued to collaborate with teams for feedback and validating our design solutions through user testing significantly helped us to become confident in our design decisions. I made sure the design is flexible and scalable for potential future payment rails.   <a className="main" href="mailto:knguyen9095@gmail.com">Contact me</a> to learn more!
                <div className="spacer" data-height="60"></div>
                <div className="spacer" data-height="96"></div>

                <div className="row ">
                  <div className="col-sm-5">
                    <h4>Splitting Up Sections</h4>

                    <p></p>

                    With banking, compliance changes daily. That drastically changed the experience we created for our users. With that in mind, slicing up into key sections help focus on specific use case without sacrificing the entire experience.

                    <div class="section-space"></div>

                  </div>

                  <div className="col-sm-6 rightalign">
                    <img style={{ width: 350}} src="/images/Portfolio/Citi/AddNewPayee/payee1.png" />

                  </div>
                </div>

              <div className="spacer" data-height="96"></div>
              <div className="spacer" data-height="96"></div>



              <div className="row ">
                <div className="col-sm-6">

                  <center><img src="/images/Portfolio/Citi/AddNewPayee/payee2.png" /></center>
                  <div class="section-space"></div>

                </div>

                <div className="col-sm-5">
                <h4>Familiar Words & Fun Image</h4>

                <p></p>
                On a more high level, leadership wants the opportunity to modernize the experience. We strive for a more personal tone and move away from bank jargon. Worked closely with the copywriter by crafting clear, friendly copy that helps the user achieves the goal.

                </div>
              </div>

              <div className="spacer" data-height="96"></div>

              <div className="row ">
                <div className="col-sm-5">
                  <h4>Improve Design System</h4>
                  <p></p>

                  My team had the opportunity to create new design patterns that didn’t exist in our design system. Some components were a bit outdated and were not accessibility friendly.
                  <div class="section-space"></div>

                </div>

                <div className="col-sm-6 rightalign">
                  <img style={{ width: 250}} src="/images/Portfolio/Citi/AddNewPayee/payee3.png" />

                </div>
              </div>
              <div className="spacer" data-height="96"></div>
              <div className="spacer" data-height="96"></div>


              <div className="row ">
                <div className="col-sm-6">

                  <center>
                  <img src="/images/Portfolio/Citi/AddNewPayee/payee4.png" style={{ width: 200 }} />
                  </center>
                  <div class="section-space"></div>

                </div>

                <div className="col-sm-5">
                <h4>Framing Delivery Options</h4>


                <p></p>

                Inform users of all the potential payment rails to select with a brief description.

                </div>
              </div>
              <div className="spacer" data-height="96"></div>
              <div className="spacer" data-height="96"></div>
              
              <div className="row ">
                <div className="col-sm-5">
                  <h4>Educating on Payment Delivery Rails</h4>
                  <p></p>

                  Allow users to understand what each payment delivery rails with a side by side comparisons. Know the difference between the fees, exchange rate, daily limits, and more.
                  <div class="section-space"></div>

                </div>

                <div className="col-sm-6 rightalign">
                  <img src="/images/Portfolio/Citi/AddNewPayee/payee5.png" style={{ width: 200 }} />

                </div>
              </div>

              <div className="spacer" data-height="96"></div>
              <div className="spacer" data-height="96"></div>

              <div className="mt-4">
                            <center><a
                              className="btn btn-default"
                              href="https://www.figma.com/proto/JioSEYm0G53faJ75tpyTTI/Citi---Add-a-Payee---Phase-3?page-id=1%3A51505&node-id=1-51825&viewport=143%2C178%2C0.2&scaling=scale-down&starting-point-node-id=1%3A51825&t=HviiIdwzF1m8WcUK-1"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              View Prototype
                             </a> </center> </div>
                             <div className="spacer" data-height="96"></div>

                </div>




          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>

          <Footer />
    </>
  );
}

export default AddPayee;
