import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Wiretransfer() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Citi/MakeaWireTransfer/thumbnail.png"/>

                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Make a Wire Transfer" />


                      <p></p>

                      The first-ever Make a Wire Transfer on the Citi mobile app. Citi's goal is to move away from traditional in-person banking to a digital platform. As we are stepping towards mobile thinking, our business goal is to increase revenue for wire transactions and reduce the cost of branch and support calls. Also, introduce a progress tracker to let customers know where their money is being processed.
                      <p></p><a className="main" href="mailto:knguyen9095@gmail.com">Contact me</a> for more indepth design process.

                       <p></p><strong>Launched in November 2019</strong>
                        <div class="section-space"></div>

                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="2019" />
                       <Label label="Role" input="UX Designer" />
                       <Label label="For" input="Citi / Pay Domain" />
                       <Label label="Team" input="2 Designers, 2 Project Managers, 1 Engineering Team" />

                    </div>
                  </div>

                   <hr></hr>

                 <div className="spacer" data-height="60"></div>
                <div className="row col section-wrapper">

                    <h2>The Problem</h2>
                  The only digital way to send a wire transfer is through the Citi's website that hasn't been updated since the 90's. No joke. Digital Payments experience too closely reflects the complexity of underlying systems. Which pairs to using unfriendly bank jargon that customers don't understand. We looked through feedbacks, surverys, and call complaints to pin out the problem. We discovered that customers were unable to find wire transfer on the website and they have no way of inputting the currency they are sending to.

                      <img className="section-image-pad" src="/images/Portfolio/Citi/MakeaWireTransfer/wiretransfer1.png" />

                </div>

                  <div class="section-space"></div>
                <div className="spacer" data-height="60"></div>



                        <h2>The Research</h2>

                        <h4> What’s the problem with the wire transfer journey on the website? </h4>
                        <div className="spacer" data-height="60"></div>
                       <div className="row">
                          <div className="col-sm aligncenter"><img className="iconleft" src="/images/Portfolio/Citi/MakeaWireTransfer/notfound.svg" />Wire Transfer is not easily discoverable on the website</div>
                          <div className=" col-sm"><img className="iconleft" src="/images/Portfolio/Citi/MakeaWireTransfer/064-document-form.svg" />Loads of information is required to complete a
wire transfer</div>

                          <div className="w-100"></div>

                          <div className="col-sm aligncenter"><img className="iconleft" src="/images/Portfolio/Citi/MakeaWireTransfer/Question.svg" />Customers are confused with old banky jargon terms</div>
                          <div className="col-sm"><img className="iconleft" src="/images/Portfolio/Citi/MakeaWireTransfer/Currency.svg" />For international transfers- there’s no live conversion and the lockdown rate is short</div>
                        </div>


                        <div className="spacer" data-height="60"></div>
                        <h4>How can we make wire transfer a mobile friendly process?</h4>
                        <ul>
                        <li>What does the customer need?</li>
                        <li>What’s our competitor's process?</li>
                        <li>What can we do better than our competitors?</li>
                        <li>How can we make the design flexible and scalable? </li>
                        </ul>

                        <div className="spacer" data-height="60"></div>
                        <h4>Understanding the basic</h4>
                        We need to understand the user's journey sending out wire transfer to someone. What do they think about first, and what's important. We conducted some survey level. (SOURCE: McKinsey survey, July 2018, N = 480)

                         <img className="section-image-pad" src="/images/Portfolio/Citi/MakeaWireTransfer/research.png" />

                          <h2>The Goals</h2>

                          <p></p>

                           <div className="question">
                           <ol class="number">
                              <li> Focus one journey at a time. </li>
                              <li> Emphasize the important information at a glance </li>
                              <li> <strong>Domestic</strong>  - Set up a Recurring Transfer. <strong>International</strong> - Able to switch between currency</li>
                              <li>How can I track where my money went? </li>
                            </ol>
                            </div>

                            <div className="spacer" data-height="60"></div>
                            <h2> Competitor Anlaysis </h2>
                                 We want to understand common patterns of sending a wire transfer process from other banks. We would like to know if there are some features already exist that we can leverage and make it better. I had to use my and coworker's personal banking to do discovery work.

                           <img className="section-image-pad" src="/images/Portfolio/Citi/MakeaWireTransfer/competitor.png" />


                            <h2>The Wireframes</h2>
                            The first wireframe design used the original Citi design system created by an agency. While doing the usability phasing, we learned that there was a change of direction to break away from the design system into a brand new concept.
                             <p></p>
                            The second wireframe showcase some of the UI treatment I've made. I explored the idea of making it easy to read and how its functionality behaves.

                          <img className="section-image-pad" src="/images/Portfolio/Citi/MakeaWireTransfer/wireframe1.png" />

                           <img className="section-image-pad" src="/images/Portfolio/Citi/MakeaWireTransfer/wireframe2.png" />


              <h2>The Outcome</h2>
               After multiple rounds of user testing, we designed a more delightful experience. We focused more on a task level on who am I going to pay, how much am I sending, and when will they receive the money. This is the third final UI iteration, and we made sure it's concise for the user. I made sure that the design is flexible enough for future money movement routing experiences (ex: ACH), and it will be seamless for the user.  <a className="main" href="mailto:knguyen9095@gmail.com">Contact me</a> to learn more!
                <div className="spacer" data-height="60"></div>
                <center><div className="row sm-pad">
                   <div className="col-sm "><h4>Domestic Wire Transfer </h4></div>
                   <div className="col-sm ">
                       <video width="275" height="412" controls>
                       <source src="/images/Portfolio/Citi/MakeaWireTransfer/domestic.mp4" type="video/mp4" />

                      </video>
                   </div>
                </div>
                </center>


                <div className="spacer" data-height="70"></div>
                <center><div className="row sm-pad">
                <div className="col-sm ">
                    <video width="275" height="412" controls>
                    <source src="/images/Portfolio/Citi/MakeaWireTransfer/international.mp4" type="video/mp4" />

                   </video>
                </div>
                   <div className="col-sm "><h4>International Wire Transfer </h4></div>

                </div>
                </center>


              <div class="section-space"></div>
              <div className="spacer" data-height="96"></div>
              <div className="mt-4">
                            <center><a
                              className="btn btn-default"
                              href="https://www.figma.com/proto/g7vLv0TdEYSdExNJGnVSHw/02042020_Send-Money-CGT-and-Wires_KN_V1?page-id=2%3A11048&node-id=8%3A11507&viewport=245%2C350%2C0.43&scaling=min-zoom&starting-point-node-id=6%3A11257"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              View Prototype
                             </a> </center> </div>
                             <div className="spacer" data-height="96"></div>

                </div>




          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>

          <Footer />
    </>
  );
}

export default Wiretransfer;
