import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Rosko() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
            <img src="/images/Portfolio/ROSKO/thumbnail.png" />
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="ROSKO Redesign" />

                        ROSKO allows you to find locations, see how many people are currently at those locations, identify gender ratios, and chat anonymously with people at those locations.Help business owners to promote their business and build relationships with their customers.
                        This is a redesigned of the user experience and user interface for ROSKO app.

                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="2018-2019" />
                       <Label label="Role" input="UX/UI Designer Intern" />
                       <Label label="Company" input="Social Savage Company" />

                    </div>
                  </div>
                  
                   <hr></hr>

                 <div className="spacer" data-height="60"></div>

                <div className="row col section-wrapper">
                  
                    <h2>Problem</h2>
                
            
                    The first version of the ROSKO app has many problematic user interface. 
                    The CEO didn’t understand why not a lot of people used the app when they first launched, so I idenitified the problem.
                    The user had trouble understanding the concept of the app – there was little to no information for the user how to use the app. 
                    There wasn’t much description on what the app does.

                  <div class="section-space"></div>

                        <div className="row">
                          <p></p>
                              <div className="col"><h4>Complicated Navigation</h4> There was a hidden navigation, which makes difficult for the user to go through the app.</div>

                              <div className="col"><h4>Redundant Features</h4> 
                                  There are many redundant and unnecessary features that can be eliminated overall
                              </div>

                              <div className="col"><h4>Unusual Color Scheme</h4>
                                It's a bit old fashion color and it does not compliment the logo. There was a lot of heavy gradients, the logo is dark and heavy, with too many different type fonts, and the buttons were not consistent.
                              </div>
                          <p></p>
                          
                          <div className="section-space"></div>
                          
                          Overall, there was a lot of confusion, which results in the user to delete the app within a minute. There was a lot of assumption that the user should know how to use the app, but the app itself lacks the common design pattern that is intuitive for the user.
                          <div className="spacer" data-height="96"></div>
                           <img src="/images/Portfolio/ROSKO/old.jpeg" /> 
                          <div className="section-space"></div>
                      
                    </div>
                  </div>





                  <div className="spacer" data-height="96"></div>
                 
                        <h2>Project Goals</h2>

                       The goal metric is achieve the retention rate of users. 
               

                  <div className="row">

                            <p></p>
                            <div className="col"> <center><h4> Simplify the layout</h4></center> </div>
                            <div className="col"> <center><h4> Showcase Core Features</h4></center> </div>
                            <div class="w-100"></div>
                            <div className="col"> <center><h4> Don't make the user think!</h4></center></div>
                            <div className="col"><center><h4> Rebrand the UI</h4></center></div>

                   </div>


                  <div className="spacer" data-height="96"></div>
                    
                        <h2>Challenges</h2>
                   
                     I still have to keep some features that the CEO wanted, such as the thumbs up and thumbs down & see if the post is positive or negative. I have to go back and forth to make sure that the CEO is happy with the outcome that they wanted. The challenge is to convince the developer to switch to a newer multi-platform app builder such as Electron for example. ROSKO developer is using Adobe Flash Builder to build the app, which is very outdated since 2012! 
                

                   <div className="spacer" data-height="96"></div>
                   
                        <h2>Key Features</h2>
                   
                    <div className="row spacer">

                            <p></p>
                            <div className="col-sm"> <center><h4> 1. Post conversation on the business </h4></center> </div>
                            <div className="col-sm"> <center><h4> 2. Allow business owners to promote and recieve live feedback</h4></center> </div>
                            <div className="col-sm"> <center><h4> 3. Show live basic demographics for that business </h4></center></div>

                   </div>

                
                     
                  <div className="spacer" data-height="96"></div>
                 
                        <h2>Design Process</h2>
                    

                   <div className="spacer" data-height="50"></div>
                   <div className="row">
                      <div className="col-sm"><img src="/images/Portfolio/ROSKO/persona1.jpeg" /></div>
                      <div className="col-sm"><img src="/images/Portfolio/ROSKO/persona2.jpeg" /></div>
                   </div>

                   <div className="spacer" data-height="96"></div>


                    <div className="row section-wrapper">
                     <div className="col-sm-4"><h2>Wireframes</h2>
                     Quick initial wireframes. 
                     </div>
                     <div className="col-sm-8"><img src="/images/Portfolio/ROSKO/wireframe.png" /></div>
                    </div>


                     <div className="spacer" data-height="96"></div>

                    <div className="row section-wrapper">
                     <div className="col-sm-5"><img src="/images/Portfolio/ROSKO/usability.png" />
                     
                     </div>
                     <div className="col-sm-6"><h2>Quick User Testing</h2>
                      I made a variety of icons onto a post-it note. One version has text, others don’t.
                      I asked the testers to reoriented the position of the single pieces.
                      Then I asked where the name go to each icon
                      Then I showed my pre-made template and they picked what they like best
                      So far the winning result is Home – Demographics – Notification – Profile – More. (They are familiarized because of common social media)
                      
                      <div className="mt-4"><a 
                          className="btn btn-default"
                          href="https://marvelapp.com/22cidba/screen/24413935"
                          spy={true}
                          smooth={true}
                          duration={500}
                      >
                        View Prototype
                      </a> </div>

                     </div>
                     
                    </div>

                    <div className="spacer" data-height="96"></div>


                  
                            <h2>Feedback</h2>
                        
                     
                            
                          <div class="section-space"></div>

                                <div className="row">
                                  <p></p>
                                      <div className="col"><h4>Unsure when to use the demographic feature</h4> They are not able to use it until they enter the business chat space. </div>

                                      <div className="col"><h4>Notification</h4> 
                                          A way to have notification for unread chat or mentions. 
                                      </div>

              </div>
      
              <div className="spacer" data-height="96"></div>
              <h2>Final</h2>
             <img className="section-image-pad" src="/images/Portfolio/ROSKO/ROSKONEW.png" />
            




              <div className="spacer" data-height="96"></div>

                  
                    <h2>Conclusion – After Thoughts</h2>
              
                   It was a difficult task to throughout the whole design by myself, however; it felt very rewarding. There are more features I could have added, but I do not want to overwhelm the developer. Missing features: Edit & Delete Posts / Comments. I suggested the idea, but the developer did not want to implement the features. Distinguish business owners vs customers – perhaps having a check mark of the business owner.I suggested the developer switch out Adobe Flash Builder to another multicross platform app.

                    I presented the whole new redesign to the stakeholder, and the company got the funding.
                  <div className="spacer" data-height="96"></div>

          </div>
        </div>
          </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Rosko
