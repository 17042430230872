import React from "react";
import { Element } from "react-scroll";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import Herosection from "../components/layouts/Herosection";
import Portfolio from "../components/layouts/Portfolio";


function Home() {

  return (
    <>
  <Header />
      <main className="content">
        <Element name="section-home">
         
            <Herosection />
        
        </Element>

      
        <Element id="section-works" name="section-works">
          <Portfolio />
        </Element>

      </main>
   
          <Footer />
    </>
  );
}

export default Home;
