import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

function Headerpage() {
  const [toggleNavbar, setToggleNavbar] = useState(false);

  const handleMobilenav = (e) => {
    e.preventDefault();
    setToggleNavbar(!toggleNavbar);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleNavbar(false);
    }
  });

  return (
    <header
      className=
        "header fixed-top "
    >
      <div className="container">

        
            <Link className="navbar navbar-brand  navbar-expand-lg navbar-dark" to="/">
             Kristine Nguyen
               </Link> 
        <nav className="navbar navbar-expand-lg navbar-dark ">
            
            <Link className="navbar navbar-brand  navbar-expand-lg navbar-dark" to="/">
            
               </Link> 
          <button
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbarNavDropdown"
            data-toggle="collapse"
            type="button"
            onClick={handleMobilenav}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              toggleNavbar
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav ml-auto scrollspy">
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="/"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </li>

              


             
              <li className="nav-item">
                <NavHashLink
                  activeClass="active"
                  className="nav-link"
                  to="/#section-works"
                 
                >
                  Work
                </NavHashLink>
              </li>
              
              <li className="nav-item">
                <a
                  href="/kristine_nguyen_resume.pdf"
                  activeClass="active"
                  className="nav-link"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Resume
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Headerpage;
