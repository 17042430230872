import React from "react";

import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Rootify() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Rootify/thumbcover.jpeg"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Rootify" />

                      Rootify visualizes your taste in music and allows you to branch out and discover new artists and tracks based on your Spotify listening history. I was the developer and lead designer for a team project CMPS 115 (Introduction of Software Engineering) at UC Santa Cruz.
                       <div className="mt-4">
                            <a 
                              className="btn btn-default"
                              href="https://github.com/knguyen9095/Rootify-1"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              View Github
                             </a> </div>


                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="September - December 2017" />
                       <Label label="Role" input="Designer & Developer" />
                       <Label label="Team" input="4 student developers" />
                       <Label label="For" input="Introduction of Software Engineering (CMPS 115) – UC Santa Cruz" />

                    </div>
                  </div>
                  
                   <hr></hr>

                 <div className="spacer" data-height="60"></div>

                <div className="row section-wrapper">
                  
                    <h2>The User Story</h2>
                
              Initially, the team knew what the product owner envisions from his idea. However, the team had trouble understanding what is the user stories. I step up and explained that it’s not “As a developer wants” but the user needs. So we brainstormed and came up with this.
                <div class="section-space"></div>


                 <div className="spacer" data-height="60"></div>
                <blockquote>“As a spotify user, I want to visualize new tracks and artists based on one of my top related artists and tracks so I can explore new music.”</blockquote>

                  
                  </div>


                  <div className="spacer" data-height="96"></div>
                  
                        <h2>The Challenge</h2>
                        Realistically the team and I only have about 6 weeks to create the web applications within our 10 weeks quarter system. 
                        It will be tough since all of us are seniors with some additional difficult classes that we get swamp with homework. 
                        The next challenge is limited time to do user research – I couldn’t fully take time to do user research because it’s based on the product owner’s idea. 
                        However, I did some research looking through Spotify Daily mixes and asking the opinions of others if they often use those features. 
                        We needed to finish the design in our first week of a sprint because judging our team, the other team members will most likely take more time learning unfamiliar languages, so we adjust accordingly. 
                        The third challenge was that there were too many ideas in the initial process, so I step up and help break down what we really need to focus on due to the skillset and time we have. 
                        Lastly, the other challenge was designed disagreements.

                    


  <div className="spacer" data-height="96"></div>
                  
                        <h2>The Goals</h2>
                        There were many different ideas and features that the team wanted to implement. However, I stepped up and break down the four important features that the team should focus on and finish before diving into the product backlog.
                     
                        <ol className="number">
                        <li>Visualize Spotify account’s short term and long term tracks and artists & populate related tracks/artists </li>
                        <li>See details about selected tracks and artists & be able to preview tracks</li>
                        <li>Create a recommended playlist from selected tracks & artists</li>
                        <li>Filter certain attributes of your top tracks and artists (and of those that are related)</li>
                        </ol>


 <div className="spacer" data-height="96"></div>
                 

                     <h3>First Iternation Sketch Process</h3>

                    <div class="section-space"></div>
                    The product owner laid out a quick sketch of his thought and ideas. I provided design critiques and feedback along the way. Here are my questions and feedbacks:
                          <div class="section-space"></div>
                      <li>At first glance, it's cluttered and doesn't seem to fit in one screen.</li>
                      <li> What is the main focus? The tree data visualization?</li>
                      <li>What's the point of the pie chart?</li>
                      <li>What does the user have to right-click to expand the tree?</li>
                      <li>Is the tree going to be web-like or structured?</li>
                      <li> Is the artist and track box will be constantly populated and remove when the user expands and hide the tracks?</li>
                      <li>How can you tell between the tracks and artists? </li>
                   
                    <img className="section-image-pad" src="/images/Portfolio/Rootify/sketch1.jpeg" />
                  
 <div className="spacer" data-height="96"></div>
                
                 
                        <h2>Design Session Take 2</h2>
                       My team and I had a quick workshop on how we can orient the layout into one screen. I made sure what is our main emphasis of the project, which is seeing the data visualization so that would take the majority of the screen space. On the main data visualization, we need a way to know what is what. So we differentiate between tracks and artists with squares and circles respectively.

                    <div class="section-space"></div>

                          The sidebar will consist of the details about the track or artist and the generate tab. We broke down into more of the details consist of. We would want to know the artist, the track, any history that we could pull from the API, learn the genre, and how visually it's related.


                    <div class="section-space"></div>
                              For the filtering, we would wanted to put it at the bottom or so. Since it's not that important for the user, it seems more of a nice to have. I did some internal user testing - many noted that the filter is not necessary but they will be curious to use it. We will hide it away with the arrow to prevent screen overload when the user comes on the screen for the first time. In addition, it reduces the screen capacity and does not distract to the main data visualization.


                    <div class="section-space"></div>
                    <img className="section-image-pad" src="/images/Portfolio/Rootify/sketch2.jpeg" />
                    <img className="section-image-pad" src="/images/Portfolio/Rootify/sketch3.jpeg" />
                    <img className="section-image-pad" src="/images/Portfolio/Rootify/sketch4.jpeg" />


<div className="spacer" data-height="96"></div>
                
                  
                        <h2>User Testing Findings</h2>
                       First finding that we discovered is how the user knows when the node of the track or artists is selected? We completely glanced over this small minuscule detail, but this is impactful. One user commented that if the tree is expanded by a lot, then they will lose their place if they look away from a screen at glance. The design solution I came up with is simply putting an outline ring around the node. So when the user selects the node, it would be highlighted green until they deselect. Another challenge I faced later is applying that same method if the user selects in the generate tab. Having multiple greens would be a conflicting mode. So what I suggested if the user is in the generate tab, then the selected node will be highlighted orange. This helps differentiate between the selection and would be easily scannable.
                    <div class="section-space"></div>
                          In the previous session, the product owner suggested selecting tracks to generate a playlist using the right-click method. However, not all users would understand that right-click will consist of that behavior. So I tested with users internally. I asked how would they select the favorite tracks and artists they found and put it in the "Generate" bucket. The majority of users assumed that simply clicking the node would automatically populate into the generate. One user did a drag and drop method - which is an interesting concept. However, this sort gives us a little bit more complexity of our team that can not figure out and codes in time. We put this in the backlog for now unless we have time to figure it out.

                    <div class="section-space"></div>
                              What I come up with using the tab method, so when the user selects the generate tab, it would be in the mode of selecting the tracks and artist on the data visualization aspect. This also helped reduce our code production load, since having a flag on call during the tab is much easier to call for as well.


                    <img className="section-image-pad" src="/images/Portfolio/Rootify/sketch5.jpeg" />

                    <img className="section-image-pad" src="/images/Portfolio/Rootify/final.webp" />

                      

              <div className="spacer" data-height="96"></div>

               
                    <h2>Results</h2>
            
                  Our team was able to finish the project 2 weeks before it due. We were able to finish all but one backlog item. We didn't improve or change much, due to our busy school schedule with studying exams and all. When we presented this project, our professor noted that this is the best group project he has seen since his teaching career. He emphasized that the project was easy and intuitive to learn.
                  <div className="spacer" data-height="60"></div>
                  
                  
                    <h2>What would I have done differently?</h2>
                
                   If I had more time to do actual design thinking, I would change the arrow at the bottom with a more prominent CTA called "Filter". Another thought: when the user selects the artists or tracks in detail mode, it would be nice to have a CTA "Add to Playlist" or "Add to Generate Playlist". So that when the user does not have to tab back and forth to the generate tab. It would reduce two clicks all together. Last, I would have emphasize the node selection more. Perhaps have a bigger halo outlined, it seems fairly not noticeable.
<div className="spacer" data-height="96"></div>


          </div> 
                </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Rootify;
