import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Alexacompliment() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Alexa/complimentbooster.png"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Alexa Compliment Booster of the Day" />

                     

                    Alexa will compliment you, if you ask.
                  <div className="mt-4">
                            <a 
                              className="btn btn-default"
                              href="https://www.amazon.com/Compliment-Booster-of-the-Day/dp/B01M5LAZNE/ref=sr_1_2?s=digital-skills&ie=UTF8&qid=1488928067&sr=1-2&keywords=Kristine+Nguyen"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              Download Here
                             </a> </div>


                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="November 2016" />
                       <Label label="Role" input="Developer" />
                       <Label label="For" input="Personal Project" />

                    </div>
                  </div>
                  </div>


          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Alexacompliment;
