import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function ClassOpen() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/ClassOpen/thumbnail.jpeg"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="[Class(OPEN)]" />

                       Help students enroll into classes, when they are on a waitlist. Get instant notification to start enrolling. 

                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="2016" />
                       <Label label="Role" input="UX/UI Designer" />
                       <Label label="For" input="Personal Project" />

                    </div>
                  </div>
                  
                   <hr></hr>

                 <div className="spacer" data-height="60"></div>

                </div>
              <div className="spacer" data-height="96"></div>
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide1.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide2.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide3.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide4.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide5.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide6.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide7.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide8.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide9.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide10.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide11.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide12.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide13.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide14.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide15.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide16.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide17.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide18.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide19.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide21.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide22.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide23.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide24.webp" />
              <img className="section-image-pad" src="/images/Portfolio/ClassOpen/slide25.webp" />


                  <div className="mt-4">
              <center><a 
                          className="btn btn-default"
                          href="https://invis.io/BK8G7IDN8"
                          spy={true}
                          smooth={true}
                          duration={500}
                      >
                        View Prototype
                      </a></center> </div>



              <div className="spacer" data-height="96"></div>


                  <div className="spacer" data-height="60"></div>
                 


          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default ClassOpen;
