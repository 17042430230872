import React from "react";
import { Element } from "react-scroll";
import Headerpage from "../components/layouts/Headerpage";
import Footer from "../components/layouts/Footer";
import Title from "../components/elements/Title";
import Label from "../components/elements/Label";

function Alexagift() {

  return (
    <>
  <Headerpage/>
      <main>
        <div className="blog-page-section">
          <div className="container">

            <div className="spacer" data-height="96"></div>
            <div className="p-30">
                <img src="/images/Portfolio/Alexa/giftgenie.png"/>
                
                <div className="spacer" data-height="40"></div>

                  <div className="row section-wrapper">
                    <div className="col-sm-8">
                      <Title title="Alexa Gift Genie" />

                    <blockquote> "Alexa, ask Gift Genie, find a gift for mom" </blockquote>

Do you have a hard time finding a random gift for your loved one?

Gift Genie will suggest a gift instantly! :)

                  <div className="mt-4">
                            <a 
                              className="btn btn-default"
                              href="https://www.amazon.com/Kristine-Nguyen-Gift-Genie/dp/B01N01I1I4/ref=sr_1_1?keywords=Kristine+Nguyen&qid=1660169485&s=digital-skills&sr=1-1"
                              spy={true}
                              smooth={true}
                              duration={500}
                          >
                              Download Here
                             </a> </div>


                    </div>

                    <div className="col-sm-4">

                       <Label label="Timeframe" input="November 2016" />
                       <Label label="Role" input="Developer" />
                       <Label label="For" input="Personal Project" />

                    </div>
                  </div>
                  </div>


          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
   
          <Footer />
    </>
  );
}

export default Alexagift;
